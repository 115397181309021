
import Vue from "vue";
import Alerter from "@/components/Alerter.vue";
import { DeviceHistorySchema, DeviceHistoriesService } from "@/commons/client";
import { Alert } from "@/commons/types";

export default Vue.extend({
  name: "DeviceHistoryListView",

  props: {
    pk: {
      type: String,
      required: true,
    },
  },

  components: {
    Alerter,
  },

  data: () => ({
    dialog: false,
    searchKey: "",
    alerts: [] as Alert[],
    histories: [] as DeviceHistorySchema[],
  }),

  computed: {
    rules(): any {
      return this.$store.state.genericRules;
    },
    pkInt(): number {
      return parseInt(this.pk);
    },
  },

  methods: {
    async getDeviceHistories(): Promise<DeviceHistorySchema[]> {
      try {
        return await DeviceHistoriesService.deviceHistoryListV1DeviceHistoriesGet(
          this.pkInt
        );
      } catch (error) {
        this.alerts.push({
          type: "error",
          text: "Errore durante il recupero delle informazioni",
        });
        return [];
      }
    },
    async search(): Promise<void> {
      this.dialog = false;
      this.$router.push({
        name: "deviceDetail",
        params: { serialNumber: this.searchKey },
      });
    },
  },

  async mounted() {
    this.histories = await this.getDeviceHistories();
  },
});
