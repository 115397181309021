
import { defineComponent } from "vue";
import { UserSetPasswordSchema } from "@/commons/client";
import { Alert } from "@/commons/types";
import Alerter from "./Alerter.vue";
import { UsersService } from "@/commons/client";

export default defineComponent({
  name: "PasswordChange",

  components: {
    Alerter,
  },

  data: () => ({
    alerts: [] as Alert[],
    password1IsClear: false,
    password2IsClear: false,
    passwordChangeData: {
      newPassword: "",
      newPasswordConfirm: "",
    },
  }),

  computed: {
    rules() {
      return this.$store.state.genericRules;
    },
    maxCardWidth() {
      /*************************************
       * Return the right card length based on
       * current breakpoint.
       *************************************/
      switch (this.$vuetify.breakpoint.name) {
        case "xs" || "sm":
          return 400;
      }
      return 550;
    },
    isValidData() {
      return (
        this.passwordChangeData.newPassword &&
        this.passwordChangeData.newPasswordConfirm &&
        this.passwordChangeData.newPassword ===
          this.passwordChangeData.newPasswordConfirm
      );
    },
  },

  methods: {
    async updatePassword() {
      if (this.isValidData) {
        try {
          const res =
            await UsersService.userSetPasswordV1UsersPkSetPasswordPatch(
              this.pk,
              { password: this.passwordChangeData.newPassword }
            );
          this.alerts.push({
            type: "success",
            text: "Password aggiornata con successo",
          });
        } catch (err) {
          this.alerts.push({
            type: "error",
            text: "Errore durante il cambio password",
          });
        }
      } else {
        this.alerts.push({
          type: "error",
          text: "Le password non coincidono",
        });
      }
    },
  },

  props: {
    pk: {
      type: String,
      required: true,
    },
  },
});
