
import Vue from "vue";
import { Alert } from "@/commons/types";
import Alerter from "@/components/Alerter.vue";
import {
  VehicleSchema,
  VehicleUpdateSchema,
  VehiclesService,
} from "@/commons/client";

export default Vue.extend({
  name: "VehicleDetailView",

  props: {
    pk: {
      type: String,
      required: true,
    },
    vehiclePk: {
      type: String,
      required: true,
    },
  },

  components: {
    Alerter,
  },

  data: () => ({
    alerts: [] as Alert[],
    vehicleData: {} as VehicleUpdateSchema,
  }),

  computed: {
    rules(): any {
      return this.$store.state.genericRules;
    },
    pkInt(): number {
      return parseInt(this.pk);
    },
    vehiclePkInt(): number {
      return parseInt(this.vehiclePk);
    },
  },

  methods: {
    setData(vehicle: VehicleSchema): void {
      this.vehicleData = {
        id: vehicle.id,
        vehicle_id: vehicle.vehicle_id,
        organization_id: vehicle.organization_id,
        plate: vehicle.plate,
      };
    },
    async getVehicle(): Promise<VehicleSchema> {
      try {
        return await VehiclesService.organizationVehiclesRetrieveV1OrganizationsPkVehiclesVehiclePkGet(
          this.pkInt,
          this.vehiclePkInt
        );
      } catch (err) {
        this.alerts.push({
          type: "error",
          text: "Errore durante il recupero del veicolo",
        });
        return {} as VehicleSchema;
      }
    },
    async updateVehicle(): Promise<void> {
      try {
        const vehicle =
          await VehiclesService.organizationVehiclesUpdateV1OrganizationsPkVehiclesVehiclePkPut(
            this.pkInt,
            this.vehiclePkInt,
            this.vehicleData
          );
        this.setData(vehicle);
        this.alerts.push({
          type: "success",
          text: "Veicolo aggiornato con successo",
        });
      } catch (err) {
        this.alerts.push({
          type: "error",
          text: "Errore durante l'aggiornamento del veicolo",
        });
      }
    },
  },

  async mounted() {
    const vehicle = await this.getVehicle();
    this.setData(vehicle);
  },
});
