
import Vue from "vue";
import { Alert } from "@/commons/types";
import Alerter from "@/components/Alerter.vue";
import { DeviceSchema, DevicesService } from "@/commons/client";

export default Vue.extend({
  name: "DeviceList",

  components: {
    Alerter,
  },

  data: () => ({
    dialog: false,
    searchKey: "",
    alerts: [] as Alert[],
    devices: [] as DeviceSchema[],
  }),

  computed: {
    rules() {
      return this.$store.state.genericRules;
    },
  },

  methods: {
    async getDevices(): Promise<DeviceSchema[]> {
      try {
        const devices = await DevicesService.deviceListV1DevicesGet();
        return devices;
      } catch (error) {
        this.alerts.push({
          type: "error",
          text: "Errore nel recupero degli etilometri",
        });
        return [];
      }
    },
    async search(): Promise<void> {
      this.dialog = false;
      this.$router.push({
        name: "deviceDetail",
        params: { serialNumber: this.searchKey },
      });
    },
  },

  async mounted() {
    this.devices = await this.getDevices();
  },
});
