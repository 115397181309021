
import { defineComponent } from "vue";
import { Alert } from "@/commons/types";
import Alerter from "@/components/Alerter.vue";
import {
  DeviceHistorySchema,
  DeviceHistoryCreateSchema,
  DeviceHistoryUpdateSchema,
  DeviceHistoriesService,
} from "@/commons/client";
import { OrganizationSchema, OrganizationsService } from "@/commons/client";

export default defineComponent({
  name: "DeviceHistoryForm",

  components: {
    Alerter,  // eslint-disable-line
  },

  props: {
    serialNumber: {
      type: String,
      required: true,
    },
    moboSerialNumber: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    loading: false,
    dialog: false,
    searchKey: "",
    alerts: [] as Alert[],
    organizations: [] as OrganizationSchema[],
    organizationSelected: {} as OrganizationSchema,
    organizationSelectedId: null as any,
    deviceHistory: {} as DeviceHistorySchema,
  }),

  computed: {
    rules() {
      return this.$store.state.genericRules;
    },
    deviceHistoryData(): DeviceHistoryCreateSchema {
      return {
        current_device_id: this.serialNumber,
        organization_id: this.organizationSelectedId,
      };
    },
    deviceHistoryUpdateData(): DeviceHistoryUpdateSchema {
      let data = { ...this.deviceHistory };
      data.organization_id = this.organizationSelectedId;
      return data;
    },
  },

  methods: {
    async getOrganizations(): Promise<OrganizationSchema[]> {
      try {
        return await OrganizationsService.organizationListV1OrganizationsGet();
      } catch (err) {
        this.alerts.push({
          type: "error",
          text: "Errore durante il recupero delle aziende",
        });
        return [];
      }
    },
    async getOrganization(): Promise<OrganizationSchema> {
      try {
        return await OrganizationsService.organizationRetrieveV1OrganizationsPkGet(
          this.deviceHistory.organization_id
        );
      } catch (err) {
        return {} as OrganizationSchema;
      }
    },
    async createDeviceHistory(): Promise<void> {
      try {
        await DeviceHistoriesService.deviceHistoryCreateV1DeviceHistoriesPost(
          this.deviceHistoryData
        );
        this.alerts.push({
          type: "success",
          text: "Dispositivo assegnato con successo",
        });
        this.dialog = false;
      } catch (err) {
        this.alerts.push({
          type: "error",
          text: "Errore durante l'assegnazione del dispositivo",
        });
      }
    },
    async getDeviceHistory(): Promise<DeviceHistorySchema> {
      try {
        return await DeviceHistoriesService.deviceHistoryRetrieveByMoboSerialV1DeviceHistoriesByMoboSerialMoboSerialGet(
          this.moboSerialNumber
        );
      } catch (err) {
        return {} as DeviceHistorySchema;
      }
    },
    async getDataIfExist(): Promise<void> {
      this.deviceHistory = await this.getDeviceHistory();
      this.organizationSelectedId = this.deviceHistory.organization_id;
    },
    async updateDeviceHistory(): Promise<void> {
      try {
        const res =
          await DeviceHistoriesService.deviceHistoryUpdateV1DeviceHistoriesPkPut(
            this.deviceHistory.uid,
            0,
            this.deviceHistoryUpdateData
          );
        this.deviceHistory = res;
        this.alerts.push({
          type: "success",
          text: "Dispositivo aggiornato con successo",
        });
      } catch (err) {
        this.alerts.push({
          type: "error",
          text: "Errore durante l'aggiornamento del dispositivo",
        });
      }
    },
    async createOrUpdateDeviceHistory(): Promise<void> {
      if (this.deviceHistory.uid) {
        await this.updateDeviceHistory();
      } else {
        await this.createDeviceHistory();
      }
    },
  },

  watch: {
    search(val) {
      if (this.organizations.length > 0) return;
      this.loading = true;

      // when search is implemented pass to the function the search key
      OrganizationsService.organizationListV1OrganizationsGet(
        undefined,
        undefined,
        this.searchKey
      )
        .then((organizations) => {
          this.organizations = organizations;
        })
        .catch((err) => {
          this.alerts.push({
            type: "error",
            text: "Errore durante il recupero delle aziende",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    this.organizations = await this.getOrganizations();
    await this.getDataIfExist();
  },
});
