
import Vue from "vue";
export default Vue.extend({
  name: "CommandForm",

  data: () => ({
    selectedCommand: { display_name: "", command: "" },
    commands: [
      {
        display_name: "Attiva GPS",
        command: "ActivateGPS()",
      },
      {
        display_name: "Disattiva GPS",
        command: "DeactivateGPS()",
      },
      {
        display_name: "Reset GPS",
        command: "ResetGPS()",
      },
    ],
  }),

  methods: {
    sendCommand() {
      if (this.selectedCommand.display_name == "") {
        this.$emit("eventAlert", {
          type: "error",
          text: "Seleziona un comando da eseguire.",
        });

        return;
      }
      try {
        // Send the operation to the server
        this.$emit("eventAlert", {
          type: "success",
          text: "Comando inviato con successo con successo",
        });
      } catch (err) {
        this.$emit("eventAlert", {
          type: "error",
          text: "Errore durante l' invio del comando",
        });
      }
    },
  },
});
