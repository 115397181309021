
import Vue from "vue";
import HelloWorld from "../components/HelloWorld.vue";

export default Vue.extend({
  name: "Home", // eslint-disable-line

  components: {
    HelloWorld,
  },
});
