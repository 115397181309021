
import Vue from "vue";
// import { defineComponent } from "vue";
import { Alert } from "@/commons/types";
import Alerter from "@/components/Alerter.vue";
import {
  OrganizationUpdateSchema,
  OrganizationSchema,
  OrganizationsService,
} from "@/commons/client";

export default Vue.extend({
  name: "OrganizationDetail",

  components: {
    Alerter,
  },

  props: {
    pk: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    alerts: [] as Alert[],
    organizationData: {} as OrganizationUpdateSchema,
  }),

  computed: {
    rules() {
      return this.$store.state.genericRules;
    },
    countries() {
      return this.$store.state.countries;
    },
    pkInt() {
      return parseInt(this.pk);
    },
  },

  methods: {
    setData(organization: OrganizationSchema): void {
      this.organizationData = {
        id: organization.id,
        name: organization.name,
        email: organization.email,
        owner_id: organization.owner_id,
        vat: organization.vat,
        country: organization.country,
        address: organization.address,
        phone_number: organization.phone_number,
        members: organization.members,
      };
    },
    async getOrganization(): Promise<OrganizationSchema> {
      try {
        return await OrganizationsService.organizationRetrieveV1OrganizationsPkGet(
          this.pkInt
        );
      } catch (err) {
        this.alerts.push({
          type: "error",
          text: "Errore nel recupero dell'azienda",
        });
        return {} as OrganizationSchema;
      }
    },
    async updateOrganization(): Promise<void> {
      try {
        const organization =
          await OrganizationsService.organizationUpdateV1OrganizationsPkPut(
            this.pkInt,
            this.organizationData
          );
        this.setData(organization);
        this.alerts.push({
          type: "success",
          text: "Azienda aggiornata con successo",
        });
      } catch (err) {
        this.alerts.push({
          type: "error",
          text: "Errore durante l'aggiornamento dell'azienda",
        });
      }
    },
  },

  async mounted() {
    const organization = await this.getOrganization();
    this.setData(organization);
  },
});
