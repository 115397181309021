
import Vue from "vue";
import { Alert } from "@/commons/types";
import Alerter from "@/components/Alerter.vue";
import { UserSchema } from "@/commons/client";
import { UsersService } from "@/commons/client";

export default Vue.extend({
  name: "UserListView",

  components: {
    Alerter,
  },

  data: () => ({
    dialog: false,
    searchKey: "",
    alerts: [] as Alert[],
    users: [] as UserSchema[],
  }),

  computed: {
    rules() {
      return this.$store.state.genericRules;
    },
  },

  methods: {
    async getUsers(username?: string): Promise<UserSchema[]> {
      try {
        const users = await UsersService.userListV1UsersGet(
          undefined,
          undefined,
          username
        );
        return users;
      } catch (error) {
        this.alerts.push({
          type: "error",
          text: "Errore nel recupero degli utenti",
        });
        return [];
      }
    },
    async search(): Promise<void> {
      this.users = await this.getUsers(this.searchKey);
      this.dialog = false;
    },
  },

  async mounted() {
    this.users = await this.getUsers();
  },
});
