
import Vue from "vue";
import { defineComponent } from "vue";
import { Alert } from "@/commons/types";
import Alerter from "@/components/Alerter.vue";
import { VehicleSchema, VehiclesService } from "@/commons/client";

export default defineComponent({
  name: "VehicleListView",

  components: {
    Alerter,
  },

  props: {
    pk: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    dialog: false,
    searchKey: "",
    alerts: [] as Alert[],
    vehicles: [] as VehicleSchema[],
  }),

  computed: {
    rules() {
      return this.$store.state.genericRules;
    },
    organizationPkInt() {
      return parseInt(this.pk);
    },
  },

  methods: {
    async getVehicles(search?: string): Promise<VehicleSchema[]> {
      try {
        return await VehiclesService.organizationVehiclesListV1OrganizationsPkVehiclesGet(
          this.organizationPkInt,
          undefined,
          undefined,
          search
        );
      } catch (err) {
        this.alerts.push({
          type: "error",
          text: "Errore durante il recupero dei veicoli",
        });
        return [];
      }
    },
    async search(): Promise<void> {
      this.vehicles = await this.getVehicles(this.searchKey);
      this.dialog = false;
    },
  },

  async mounted() {
    this.vehicles = await this.getVehicles();
  },
});
