import Vue from "vue";
import VueRouter, { RouteConfig, Route } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import UserListView from "../views/UserListView.vue";
import UserCreateView from "../views/UserCreateView.vue";
import UserDetailView from "../views/UserDetailView.vue";
import OrganizationListView from "../views/OrganizationListView.vue";
import OrganizationDetailView from "../views/OrganizationDetailView.vue";
import OrganizationCreateView from "../views/OrganizationCreateView.vue";
import DeviceListView from "../views/DeviceListView.vue";
import DeviceCreateView from "../views/DeviceCreateView.vue";
import DeviceDetailView from "../views/DeviceDetailView.vue";
import VehicleListView from "../views/VehicleListView.vue";
import VehicleCreateView from "../views/VehicleCreateView.vue";
import VehicleDetailView from "../views/VehicleDetailView.vue";
import DeviceHistoryListView from "../views/DeviceHistoryListView.vue";
import store from "../store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/users",
    name: "userList",
    component: UserListView,
  },
  {
    path: "/users/create",
    name: "userCreate",
    component: UserCreateView,
  },
  {
    path: "/users/:pk",
    name: "userDetail",
    component: UserDetailView,
    props: true,
  },
  {
    path: "/organizations",
    name: "organizationList",
    component: OrganizationListView,
  },
  {
    path: "/organizations/create",
    name: "organizationCreate",
    component: OrganizationCreateView,
  },
  {
    path: "/organizations/:pk",
    name: "organizationDetail",
    component: OrganizationDetailView,
    props: true,
  },
  {
    path: "/organizations/:pk/vehicles",
    name: "vehicleList",
    component: VehicleListView,
    props: true,
  },
  {
    path: "/organizations/:pk/vehicles/create",
    name: "vehicleCreate",
    component: VehicleCreateView,
    props: true,
  },
  {
    path: "/organizations/:pk/vehicles/:vehiclePk",
    name: "vehicleDetail",
    component: VehicleDetailView,
    props: true,
  },
  {
    path: "/organizations/:pk/devicehistories",
    name: "deviceHistoryList",
    component: DeviceHistoryListView,
    props: true,
  },
  {
    path: "/devices",
    name: "deviceList",
    component: DeviceListView,
  },
  {
    path: "/devices/create",
    name: "deviceCreate",
    component: DeviceCreateView,
  },
  {
    path: "/devices/:serialNumber",
    name: "deviceDetail",
    component: DeviceDetailView,
    props: true,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to: Route, from: Route, next: (...args: any[]) => void) => {
  if (to.name !== "login" && to.name !== "home" && store.getters.isAnonymous) {
    next({ name: "login" });
  } else {
    next();
  }
});

export default router;
