
import Vue from "vue";
import { OpenAPI } from "@/commons/client";

export default Vue.extend({
  name: "Navbar", // eslint-disable-line
  data: () => ({
    showDrawer: true,
    links: [
      {
        icon: "mdi-view-dashboard",
        text: "Main",
        route: { name: "home" },
      },
      {
        icon: "mdi-account",
        text: "Users",
        route: { name: "userList" },
      },
      {
        icon: "mdi-domain",
        text: "Organizations",
        route: { name: "organizationList" },
      },
      // {
      //   icon: "mdi-truck-fast",
      //   text: "Veicoli",
      //   route: { name: "vehicleList" },
      // },
      {
        icon: "mdi-devices",
        text: "Devices",
        route: { name: "deviceList" },
      },
      // {
      //   icon: "mdi-transit-connection-horizontal",
      //   text: "Device Histories",
      //   route: { name: "deviceHistoryList" },
      // },
    ],
  }),
  computed: {
    isAuthenticated() {
      return !this.$store.getters.isAnonymous;
    },
  },
  methods: {
    toggleDrawer() {
      this.showDrawer = !this.showDrawer;
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "login" });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
});
