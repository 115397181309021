
import Vue from "vue";
import { Alert } from "@/commons/types";
import Alerter from "@/components/Alerter.vue";
import { OrganizationSchema, OrganizationsService } from "@/commons/client";

export default Vue.extend({
  name: "OrganizationListView",

  components: {
    Alerter,
  },

  data: () => ({
    dialog: false,
    searchKey: "",
    alerts: [] as Alert[],
    organizations: [] as OrganizationSchema[],
  }),

  computed: {
    rules() {
      return this.$store.state.genericRules;
    },
  },

  methods: {
    async getOrganizations(search?: string): Promise<OrganizationSchema[]> {
      try {
        return await OrganizationsService.organizationListV1OrganizationsGet(
          undefined,
          undefined,
          search
        );
      } catch (err) {
        this.alerts.push({
          type: "error",
          text: "Errore nel recupero delle aziende",
        });
        return [];
      }
    },
    async search(): Promise<void> {
      this.organizations = await this.getOrganizations(this.searchKey);
      this.dialog = false;
    },
  },

  async mounted() {
    this.organizations = await this.getOrganizations();
  },
});
