
import Vue from "vue";
import { Alert } from "@/commons/types";
import Alerter from "@/components/Alerter.vue";
import {
  OrganizationCreateSchema,
  OrganizationSchema,
  OrganizationsService,
} from "@/commons/client";

export default Vue.extend({
  name: "OrganizationCreateView",

  data: () => ({
    alerts: [] as Alert[],
    organizationFormData: {} as OrganizationCreateSchema,
  }),

  components: {
    Alerter,
  },

  computed: {
    rules() {
      return this.$store.state.genericRules;
    },
    countries() {
      return this.$store.state.countries;
    },
    maxCardWidth() {
      /*************************************
       * Return the right card length based on
       * current breakpoint.
       *************************************/
      switch (this.$vuetify.breakpoint.name) {
        case "xs" || "sm":
          return 400;
      }
      return 550;
    },
    isValidForm() {
      return true;
    },
    ownerIdQuery(): number | undefined {
      if (
        this.$route.query.ownerId !== null &&
        this.$route.query.ownerId !== undefined &&
        typeof this.$route.query.ownerId === "string"
      ) {
        return parseInt(this.$route.query.ownerId);
      }
      return undefined;
    },
  },

  methods: {
    async createOrganization() {
      try {
        const organization =
          await OrganizationsService.organizationCreateV1OrganizationsPost(
            this.organizationFormData,
            this.ownerIdQuery
          );
        this.$router.push({ name: "organizationList" });
      } catch (err) {
        this.alerts.push({
          type: "error",
          text: "Errore durante la creazione dell'azienda",
        });
      }
    },
  },
});
