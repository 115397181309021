
import Vue from "vue";
// import { defineComponent } from "vue";
import { Alert } from "@/commons/types";
import Alerter from "@/components/Alerter.vue";
import DeviceHistoryForm from "@/components/DeviceHistoryForm.vue";
import {
  DeviceSchema,
  DeviceUpdateSchema,
  DevicesService,
} from "@/commons/client";
import CommandForm from "../components/CommandForm.vue";

export default Vue.extend({
  name: "DeviceDetailView",

  components: {
    Alerter,
    DeviceHistoryForm,
    CommandForm,
  },

  props: {
    serialNumber: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    alerts: [] as Alert[],
    deviceData: {} as DeviceUpdateSchema,
  }),

  computed: {
    rules() {
      return this.$store.state.genericRules;
    },
  },

  methods: {
    setData(device: DeviceSchema): void {
      this.deviceData = {
        id: device.id,
        serial_number: device.serial_number,
        motherboard_serial: device.motherboard_serial,
        imei: device.imei,
      };
    },
    async getDevice(): Promise<DeviceSchema> {
      try {
        return await DevicesService.deviceRetrieveV1DevicesSerialNumberGet(
          this.serialNumber
        );
      } catch (err) {
        this.alerts.push({
          type: "error",
          text: "Errore nel recupero del dispositivo",
        });
        return {} as DeviceSchema;
      }
    },
    async updateDevice(): Promise<void> {
      try {
        const device =
          await DevicesService.deviceUpdateV1DevicesSerialNumberPut(
            this.serialNumber,
            this.deviceData
          );
        this.setData(device);
        this.alerts.push({
          type: "success",
          text: "Device aggiornato correttamente",
        });
      } catch (err) {
        this.alerts.push({
          type: "error",
          text: "Errore nell'aggiornamento del device",
        });
      }
    },
    async deleteDevice(): Promise<void> {
      const confirmDelete = confirm(
        "Sei sicuro di voler cancellare il dispositivo?"
      );
      if (confirmDelete) {
        try {
          await DevicesService.deviceDeleteV1DevicesSerialNumberDelete(
            this.serialNumber
          );
          this.$router.push({ name: "deviceList" });
        } catch (err) {
          this.alerts.push({
            type: "error",
            text: "Errore nella cancellazione del device",
          });
        }
      }
    },
    handleEventAlert(alert: Alert) {
      this.alerts.push({ type: alert.type, text: alert.text });
      return 1;
    },
  },

  // async mounted() {
  //   const device = await this.getDevice();
  //   this.setData(device);
  // },

  created() {
    this.getDevice().then((device) => this.setData(device));
  },
});
