
import Vue from "vue";
import { UserCreateSchema } from "@/commons/client";
import { UserSchema } from "@/commons/client";
import { UsersService } from "@/commons/client";
import { Alert } from "@/commons/types";
import Alerter from "@/components/Alerter.vue";

export default Vue.extend({
  name: "UserCreateView",

  components: {
    Alerter,
  },

  data: () => ({
    passwordIsClear: false,
    // userData: {
    //   username: "",
    //   password: "",
    //   email: "",
    //   first_name: "",
    //   last_name: "",
    // },
    alerts: [] as Alert[],
    userData: {} as UserCreateSchema,
    // user: {} as UserCreateSchema,
  }),

  computed: {
    rules() {
      return this.$store.state.genericRules;
    },
    isValidForm() {
      return true;
    },
  },

  methods: {
    showHidePassword() {
      this.passwordIsClear = !this.passwordIsClear;
    },
    async createUser(): Promise<void> {
      try {
        const user = await UsersService.userCreateV1UsersPost(this.userData);
        this.alerts.push({
          type: "success",
          text: "Utente creato con successo",
        });
        this.userData = {} as UserCreateSchema;
        this.$router.push({
          name: "organizationCreate",
          query: { ownerId: `${user.id}` },
        });
      } catch (err) {
        this.alerts.push({
          type: "error",
          text: "Errore durante la creazione dell'utente",
        });
      }
    },
  },
});
