
import Vue from "vue";
import { DeviceSchema, DeviceCreateSchema } from "@/commons/client";
import { DevicesService } from "@/commons/client";
import { Alert } from "@/commons/types";
import Alerter from "@/components/Alerter.vue";

export default Vue.extend({
  name: "DeviceCreateView",

  components: {
    Alerter,
  },

  data: () => ({
    alerts: [] as Alert[],
    deviceData: {} as DeviceCreateSchema,
  }),

  computed: {
    rules() {
      return this.$store.state.genericRules;
    },
  },

  methods: {
    async createDevice(): Promise<void> {
      try {
        const res = await DevicesService.deviceCreateV1DevicesPost(
          this.deviceData
        );
        this.alerts.push({
          type: "success",
          text: "Etilometro creato con successo",
        });
      } catch (err) {
        this.alerts.push({
          type: "error",
          text: "Errore durante la creazione dell'etilometro",
        });
      }
    },
  },
});
