/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeviceCreateSchema } from '../models/DeviceCreateSchema';
import type { DeviceSchema } from '../models/DeviceSchema';
import type { DeviceSetSerialNumberSchema } from '../models/DeviceSetSerialNumberSchema';
import type { DeviceUpdateSchema } from '../models/DeviceUpdateSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DevicesService {

    /**
     * Device List
     * @param skip
     * @param limit
     * @returns DeviceSchema Successful Response
     * @throws ApiError
     */
    public static deviceListV1DevicesGet(
        skip?: number,
        limit: number = 100,
    ): CancelablePromise<Array<DeviceSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/devices/',
            query: {
                'skip': skip,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Device Create
     * @param requestBody
     * @returns DeviceSchema Successful Response
     * @throws ApiError
     */
    public static deviceCreateV1DevicesPost(
        requestBody: DeviceCreateSchema,
    ): CancelablePromise<DeviceSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/devices/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                409: `Conflict`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Device Retrieve
     * @param serialNumber
     * @returns DeviceSchema Successful Response
     * @throws ApiError
     */
    public static deviceRetrieveV1DevicesSerialNumberGet(
        serialNumber: string,
    ): CancelablePromise<DeviceSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/devices/{serial_number}/',
            path: {
                'serial_number': serialNumber,
            },
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Device Update
     * @param serialNumber
     * @param requestBody
     * @returns DeviceSchema Successful Response
     * @throws ApiError
     */
    public static deviceUpdateV1DevicesSerialNumberPut(
        serialNumber: string,
        requestBody: DeviceUpdateSchema,
    ): CancelablePromise<DeviceSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/devices/{serial_number}/',
            path: {
                'serial_number': serialNumber,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Device Delete
     * @param serialNumber
     * @returns void
     * @throws ApiError
     */
    public static deviceDeleteV1DevicesSerialNumberDelete(
        serialNumber: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/devices/{serial_number}/',
            path: {
                'serial_number': serialNumber,
            },
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Device Set Serial Number
     * @param serialNumber
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deviceSetSerialNumberV1DevicesSerialNumberSetSerialNumberPatch(
        serialNumber: string,
        requestBody: DeviceSetSerialNumberSchema,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/devices/{serial_number}/set_serial_number/',
            path: {
                'serial_number': serialNumber,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

}
