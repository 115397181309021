
import { defineComponent } from "vue";
import { Alert } from "@/commons/types";
import Alerter from "@/components/Alerter.vue";
import {
  VehicleSchema,
  VehicleCreateSchema,
  VehiclesService,
} from "@/commons/client";

export default defineComponent({
  name: "VehicleCreateView",

  components: {
    Alerter,
  },

  props: {
    pk: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    alerts: [] as Alert[],
    vehicleData: {} as VehicleCreateSchema,
  }),

  computed: {
    rules() {
      return this.$store.state.genericRules;
    },
    organizationPkInt() {
      return parseInt(this.pk);
    },
  },

  methods: {
    async createVehicle(): Promise<void> {
      try {
        await VehiclesService.organizationVehiclesCreateV1OrganizationsPkVehiclesPost(
          this.organizationPkInt,
          this.vehicleData
        );
        this.alerts.push({
          type: "success",
          text: "Veicolo creato con successo",
        });
        this.vehicleData = {} as VehicleCreateSchema;
      } catch (err) {
        this.alerts.push({
          type: "error",
          text: "Errore nella creazione del veicolo",
        });
      }
    },
  },
});
