
import Vue from "vue";
import { Alert } from "@/commons/types";
import Alerter from "@/components/Alerter.vue";
import { OpenAPI } from "@/commons/client";
import { AuthService, Body_login_v1_auth_token__post } from "@/commons/client";

export default Vue.extend({
  name: "Login",  // eslint-disable-line

  components: {
    Alerter,
  },

  data: () => ({
    loginData: {} as Body_login_v1_auth_token__post,
    passwordIsClear: false,
    alerts: [] as Alert[],
  }),

  computed: {
    rules() {
      /**********************
       * Get rules from store
       **********************/
      return this.$store.state.genericRules;
    },
    maxCardWidth() {
      /*************************************
       * Return tthe right card length based on
       * current breakpoint.
       *************************************/
      switch (this.$vuetify.breakpoint.name) {
        case "xs" || "sm":
          return 400;
      }
      return 550;
    },
  },

  methods: {
    showHidePassword() {
      this.passwordIsClear = !this.passwordIsClear;
    },
    async doLogin() {
      /**********************
       * Do login
       **********************/
      try {
        const res = await AuthService.loginV1AuthTokenPost(this.loginData);
        OpenAPI.TOKEN = res.access_token;
        this.$router.push({ name: "home" });
        this.$store.dispatch("login", res.access_token);
      } catch (err) {
        console.log(err);
        this.alerts.push({
          type: "error",
          text: "Errore in fase di login",
        });
      }
    },
  },
});
