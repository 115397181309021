
import Vue from "vue";
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";
import { OpenAPI } from "./commons/client";

export default Vue.extend({
  name: "App",

  data: () => ({
    //
  }),

  components: {
    Navbar,
    Footer,
  },

  created() {
    OpenAPI.TOKEN = this.$store.state.token;
  },
});
