
import Vue from "vue";
import { Alert } from "@/commons/types";
import Alerter from "@/components/Alerter.vue";
import { UserSchema, UsersService, UserUpdateSchema } from "@/commons/client";
import PasswordChangeVue from "@/components/PasswordChange.vue";

export default Vue.extend({
  name: "UserDetailView",

  components: {
    PasswordChangeVue,
    Alerter,
  },

  data: () => ({
    alerts: [] as Alert[],
    userData: {} as UserUpdateSchema,
  }),

  computed: {
    rules() {
      return this.$store.state.genericRules;
    },
    intPk() {
      return parseInt(this.$route.params.pk);
    },
  },

  methods: {
    async getUser(): Promise<UserSchema> {
      return await UsersService.userRetrieveV1UsersPkGet(this.intPk);
    },
    async updateUser(): Promise<void> {
      try {
        const user = await UsersService.userUpdateV1UsersPkPut(
          this.intPk,
          this.userData
        );
        this.setUser(user);
        this.alerts.push({
          type: "success",
          text: "Utente aggiornato con successo",
        });
      } catch (err) {
        this.alerts.push({
          type: "error",
          text: "Errore durante l'aggiornamento dell'utente",
        });
      }
    },
    setUser(user: UserSchema): void {
      this.userData = {
        username: user.username,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        is_active: user.is_active,
      };
      // this.userData.username = user.username;
      // this.userData.email = user.email;
      // this.userData.first_name = user.first_name;
      // this.userData.last_name = user.last_name;
      // this.userData.is_active = user.is_active;
    },
  },

  props: {
    pk: {
      type: String,
      required: true,
    },
  },

  async mounted() {
    const user = await this.getUser();
    this.setUser(user);
  },
});
